import React from 'react';

import { IPostCard, PostCard } from '../../Card';

import style from './AuthorPostGrid.scss';

interface IProps {
  posts: {
    node: IPostCard;
  }[];
}

export const AuthorPostGrid = ({ posts }: IProps) => (
  <div className={style.wrapper}>
    {posts.map(({ node }, index) => (
      <PostCard key={index} post={node} />
    ))}
  </div>
);
