import React from 'react';

import { Avatar } from '../components/Avatar/Avatar';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { AuthorPostGrid } from '../components/Post/Grid/Author';
import Posts from '../images/Posts';

import style from '../styles/pages/author.scss';

import cn from 'classnames';
import { graphql } from 'gatsby';

interface IAuthorProps {
  data: {
    ghostAuthor: {
      slug: string;
      name: string;
      cover_image: string;
      profile_image: string;
      website: string;
      bio: string;
      location: string;
      facebook: string;
      twitter: string;
    };
    allGhostPost: any;
    authorsBioFullJson: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */
const Author = ({ data, location, pageContext }: IAuthorProps) => {
  const author = data.ghostAuthor;
  const posts = data.allGhostPost.edges;

  // Note, some authors have no full bio.
  let { bio: authorBio } = data.authorsBioFullJson || {};
  if (!authorBio) {
    authorBio = author.bio;
  }

  return (
    <>
      <MetaData data={data} location={location} />
      <Layout headerClassName={style.header} mainClass={style.main}>
        {pageContext.humanPageNumber === 1 && (
          <div className={style.authorWrapper}>
            <div className="container">
              <Avatar profile_image={author.profile_image} name={author.name} />
              <h1>{author.name}</h1>
              <div className={style.count}>
                <Posts />
                {data.allGhostPost.totalCount}{' '}
                {data.allGhostPost.totalCount === 1 ? 'post' : 'posts'}
              </div>
              {author.bio && <p dangerouslySetInnerHTML={{ __html: authorBio }} />}
            </div>
          </div>
        )}
        <section
          className={cn(style.authorPage, { [style.firstPage]: pageContext.humanPageNumber === 1 })}
        >
          <div className="container">
            <AuthorPostGrid posts={posts} />
            <Pagination className={style.pagination} pageContext={pageContext} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Author;

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
    }
    authorsBioFullJson(slug: { eq: $slug }) {
      bio
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
          }
          html
          tags {
            name
            slug
          }
        }
      }
    }
  }
`;
